import styled from "styled-components"

const caseSingleStyled = styled.div`
  .caseSingle {
    &__container {
      max-width: 880px;
      @media (max-width: 940px) {
        max-width: 600px;
      }
      @media (max-width: 680px) {
        max-width: calc(100% - 40px);
      }
      @media (max-width: 450px) {
        max-width: calc(100% - 20px);
      }
    }
    &__header {
      &:hover {
        .caseSingle__header__background {
          img {
            transition: 0.3s all linear;
            transform: scale(1.3) rotate(5deg);
          }
        }
      }
      &__image {
        position: relative;
      }
      &__background {
        max-width: 100%;
        img {
          transition: 0.3s all linear !important;
        }
      }
      &__logo {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__heading {
      font-size: 48px;
      font-weight: 400;
      padding: 30px 0;
      @media (max-width: 1180px) {
        font-size: 40px;
        line-height: 38px;
        padding-bottom: 30px;
      }
      @media (max-width: 540px) {
        font-size: 35px;
        line-height: 30px;
      }
      @media (max-width: 450px) {
        font-size: 30px;
        line-height: 28px;
      }
    }
    &__content {
      &:last-child {
        padding-bottom: 0;
      }
      h2 {
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 30px;
        line-height: 1.5em;
      }
      p {
        padding-bottom: 1em;
        line-height: 2em;
      }
      strong {
        color: #05b7ba;
        em {
          font-weight: 400;
          color: #05b7ba;
        }
      }
      em {
        color: #05b7ba;
        strong {
          font-weight: 400;
        }
      }
    }
    &__solutions {
      img {
        padding-top: 10px;
        margin-right: 30px;
        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
    &__footer {
      border-top: 1px solid #e0e6ff;
      margin-top: 50px;
      /* padding-top: 50px; */
      padding-bottom: 100px;
    }
    &__more {
      display: flex;
      @media (max-width: 940px) {
        flex-direction: column;
      }
      .box__background {
        @media (max-width: 940px) {
          width: 100% !important;
        }
      }
    }
  }
`

export default caseSingleStyled
