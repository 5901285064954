import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import CaseSingleStyled from "../components/styles/caseSingleStyles"
import Img from "gatsby-image"
import Link from "gatsby-link"
import { useStaticQuery, graphql } from "gatsby"
import Box from "../components/box"

const CaseStudiesTemplate = ({ pageContext }) => {
  const { title, acf, id } = pageContext
  const {
    case_single_heading,
    case_single_description,
    case_big_bg,
    case_logo,
    case_single_solutions_1,
    case_single_solutions_2,
    case_single_solutions_3,
  } = acf

  const { allWordpressWpCaseStudies } = useStaticQuery(
    graphql`
      query {
        allWordpressWpCaseStudies(limit: 3) {
          nodes {
            id
            slug
            acf {
              case_list_button_color
              case_list_button_hover_color
              case_list_button_text
              case_list_description
              case_list_heading
              case_logo {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 240, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
              case_small_bg {
                source_url
                title
                localFile {
                  childImageSharp {
                    fixed(width: 420, quality: 90) {
                      srcSetWebp
                      srcWebp
                      srcSet
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  let i = 1

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <CaseSingleStyled>
        <Layout>
          <article className="background__white">
            <div className="container caseSingle__container">
              <header className="caseSingle__header">
                <p className="heading--1">
                  <Link to="/case-studies">CASE STUDIES - </Link>
                  {case_single_heading}
                </p>
                <div className="caseSingle__header__image">
                  <Img
                    fixed={case_big_bg.localFile.childImageSharp.fixed}
                    alt={case_single_heading}
                    className="caseSingle__header__background"
                  />
                  <Img
                    fixed={case_logo.localFile.childImageSharp.fixed}
                    alt={case_single_heading}
                    className="caseSingle__header__logo"
                  />
                </div>
              </header>
              <section className="caseSingle__main">
                <h1 className="caseSingle__heading">{case_single_heading}</h1>
                <div
                  className="content caseSingle__content"
                  dangerouslySetInnerHTML={{ __html: case_single_description }}
                ></div>
              </section>
              <secion className="caseSingle__solutions">
                {case_single_solutions_1 ? (
                  <img
                    src={case_single_solutions_1.source_url}
                    alt={case_single_solutions_1.title}
                  />
                ) : (
                  ""
                )}
                {case_single_solutions_2 ? (
                  <img
                    src={case_single_solutions_2.source_url}
                    alt={case_single_solutions_2.title}
                  />
                ) : (
                  ""
                )}
                {case_single_solutions_3 ? (
                  <img
                    src={case_single_solutions_3.source_url}
                    alt={case_single_solutions_3.title}
                  />
                ) : (
                  ""
                )}
              </secion>
              <footer className="caseSingle__footer">
                <h2 className="heading--3">Zobacz inne Case Studies</h2>
                <div className="caseSingle__more">
                  {allWordpressWpCaseStudies.nodes.map((item, index) => {
                    if (item.id !== id && i <= 2) {
                      i++
                      return (
                        <>
                          <Box
                            key={item.id}
                            slug={item.slug}
                            heading={item.acf.case_list_heading}
                            description={item.acf.case_list_description}
                            background={item.acf.case_small_bg}
                            image={item.acf.case_logo}
                            buttonColor={item.acf.case_list_button_color}
                            buttonColorHover={
                              item.acf.case_list_button_hover_color
                            }
                            buttonText={item.acf.case_list_button_text}
                            url={`/case-studies/${item.slug}`}
                          />
                        </>
                      )
                    } else {
                      return <></>
                    }
                  })}
                </div>
              </footer>
            </div>
          </article>
        </Layout>
      </CaseSingleStyled>
    </>
  )
}

export default CaseStudiesTemplate
